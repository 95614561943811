import { Route, Switch } from "react-router";
import RoutesDefinitions from "./RoutesDefinitions.json";
import Larsgarden from "./Larsgarden.jsx";
import {
  Hagen,
  Romma,
  Maten,
  Vertskapet,
  Plassering,
  Aktivitetar,
  Historia,
  Kontakt,
} from "./Pages";

const Router = ({ language }) => {
  let routes = [];

  let routesContents = [
    { name: "Romma", component: Romma },
    { name: "Maten", component: Maten },
    { name: "Plassering", component: Plassering },
    { name: "Aktivitetar", component: Aktivitetar },
    { name: "Hagen", component: Hagen },
    { name: "Historia", component: Historia },
    { name: "Vertskapet", component: Vertskapet },
    { name: "Kontakt", component: Kontakt },

    { name: "Rooms", component: Romma },
    { name: "Food", component: Maten },
    { name: "Location", component: Plassering },
    { name: "Activities", component: Aktivitetar },
    { name: "Garden", component: Hagen },
    { name: "History", component: Historia },
    { name: "About", component: Vertskapet },
    { name: "Contact", component: Kontakt },
    { name: "", component: <p>Empty</p> },
  ];
  RoutesDefinitions.forEach((routeLanguage, i, a) => {
    if (routeLanguage.isActive) {
      routeLanguage.pages.forEach((page) => {
        if (page.isActive) {
          let content = routesContents.find(
            (r) => r.name.toLowerCase() === page.name.toLowerCase()
          );
          /*if (content) {
            content = content.component;
          } else {
            content = page.name;
          }*/
          if (content) {
            routes.push(
              <Route key={page.path} path={page.path} exact>
                <content.component language={language} />
              </Route>
            );
          } else {
            routes.push(
              <Route key={page.path} path={page.path} exact>
                <p>{page.name}</p>
              </Route>
            );
          }
        }
      });
    }
  });
  return (
    <Switch>
      <Route path="/" exact>
        <Larsgarden language={language} />
      </Route>
      {routes}
      <Route>
        <p>Side ikkje funnet</p>
      </Route>
    </Switch>
  );
};

export default Router;
