import React, { useEffect, useState } from "react";

import Carousel, { CarouselItem } from "../components/carousel/Carousel";
import styles from "./Hagen.module.css";
import { Text, EngText } from "../assets/TextResources";
import {
  Hagen01,
  Hagen02,
  Hagen03,
  Hagen04,
  Hagen05,
  Hagen06,
  Hagen07,
  Hagen08,
  Hagen09,
  LogoGreenSimple,
} from "../assets/img/index.js";
import { GApageView } from "../index.js";

const Hagen = ({ language }) => {
  const [textResource, setResource] = useState(Text);
  useEffect(() => {
    GApageView("/Hagen");
  }, []);
  useEffect(() => {
    language === "ENG" ? setResource(EngText) : setResource(Text);
  }, [language]);

  return (
    <div className={styles.garden_container}>
      <img
        src={Hagen01}
        alt="Overview of the garden"
        className={styles.garden_image}
      />
      <div className={styles.garden_paragraph_wrapper}>
        <h1 className={styles.garden_title_paragraph}>
          {textResource.GARDEN.TITLE}
        </h1>
        <p>{textResource.GARDEN.FIRST_PARAGRAPH}</p>
      </div>
      <Carousel language={language}>
        <CarouselItem>
          <img src={Hagen02} alt="the garden" className={styles.garden_image} />
        </CarouselItem>
        <CarouselItem>
          <img src={Hagen03} alt="the garden" className={styles.garden_image} />
        </CarouselItem>
        <CarouselItem>
          <img src={Hagen04} alt="the garden" className={styles.garden_image} />
        </CarouselItem>
        <CarouselItem>
          <img src={Hagen05} alt="the garden" className={styles.garden_image} />
        </CarouselItem>
        <CarouselItem>
          <img src={Hagen06} alt="the garden" className={styles.garden_image} />
        </CarouselItem>
        <CarouselItem>
          <img src={Hagen07} alt="the garden" className={styles.garden_image} />
        </CarouselItem>
        <CarouselItem>
          <img src={Hagen08} alt="the garden" className={styles.garden_image} />
        </CarouselItem>
        <CarouselItem>
          <img src={Hagen09} alt="the garden" className={styles.garden_image} />
        </CarouselItem>
      </Carousel>
      <div className={styles.garden_logo_image_wrapper}>
        <img
          className={styles.garden_logo_image}
          src={LogoGreenSimple}
          alt="LogoGreenLeafSimple"
        />
      </div>
    </div>
  );
};
export default Hagen;
