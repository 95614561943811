import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import FrontImage01 from "../../assets/img/photos/frontpage05.png";
import FrontImage06 from "../../assets/img/photos/frontpage06.jpg";
import Tunet from "../../assets/img/photos/Tunet.jpg";
import { LogoGreen } from "../../assets/img/index";
import Gamlestova from "../../assets/img/photos/Gamlestova.jpg";
import { GApageView } from "../../index.js";
import { Text, EngText } from "../../assets/TextResources";

const Home = ({ language }) => {
  const [textResource, setResource] = useState(Text);

  useEffect(() => {
    GApageView("/");
  }, []);
  useEffect(() => {
    language === "ENG" ? setResource(EngText) : setResource(Text);
  }, [language]);

  return (
    <div className={styles.home_container}>
      <img
        src={FrontImage01}
        alt="scenery"
        className={styles.home_hero_image}
      />
      <div className={styles.home_paragraph}>
        <p className={styles.home_first_paragraph_text}>
          {textResource.HOME.FIRST_PARAGRAPH}
        </p>
      </div>
      <img
        src={FrontImage06}
        alt="scenery"
        className={styles.home_hero_image}
      />
      <div className={styles.home_paragraph}>
        <p className={styles.home_second_paragraph_text}>
          {textResource.HOME.SEC_PARAGRAPH}
        </p>
      </div>
      <img src={Tunet} alt="Back of house" className={styles.home_hero_image} />
      <div className={styles.home_paragraph}>
        <img
          src={LogoGreen}
          alt="LogoGreen"
          className={styles.home_full_logo_image}
        />
        <p className={styles.home_third_paragraph_text}>
          {textResource.HOME.THIRD_PARAGRAPH}
        </p>
      </div>
      <img
        src={Gamlestova}
        alt="Livingroom"
        className={styles.home_hero_image}
      />
    </div>
  );
};

export default Home;
