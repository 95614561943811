import React from "react";
import Home from "./components/home/Home";
import styles from "./Larsgarden.module.css";

const Larsgarden = ({ language }) => {
  return (
    <div className={styles.container}>
      <Home language={language} />
    </div>
  );
};

export default Larsgarden;
