import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import styles from "./Carousel.module.css";

import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";

export const CarouselItem = ({ children }) => {
  return <div className={styles.carousel_item}>{children}</div>;
};

const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  });

  return (
    <div
      {...handlers}
      className={styles.carousel}
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <button
        className={`${styles.carousel_buttons} ${styles.carousel_button_previous}`}
        onClick={() => {
          updateIndex(activeIndex - 1);
        }}
      >
        <WestOutlinedIcon
          // fontSize="medium"
          sx={{
            display: "block",
            margin: "auto",
            width: { xs: "16px", sm: "30px" },
          }}
        />
      </button>
      <button
        className={`${styles.carousel_buttons} ${styles.carousel_button_next}`}
        onClick={() => {
          updateIndex(activeIndex + 1);
        }}
      >
        <EastOutlinedIcon
          sx={{
            display: "block",
            margin: "auto",
            width: { xs: "16px", sm: "30px" },
          }}
        />
      </button>
      <div
        className={styles.inner}
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
      <div className={styles.indicators}>
        {React.Children.map(children, (child, index) => {
          return (
            <button
              className={`${index === activeIndex ? styles.active : ""}`}
              onClick={() => {
                updateIndex(index);
              }}
            ></button>
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;
