import React, { useEffect, useState } from "react";
import styles from "./Kontakt.module.css";
import Kontakt01 from "../assets/img/photos/kontakt/Kontakt.webp";
import { GApageView } from "../index.js";
import { Text, EngText } from "../assets/TextResources";

const Kontakt = ({ language }) => {
  const [textResource, setResource] = useState(Text);

  useEffect(() => {
    GApageView("/Kontakt");
  }, []);
  useEffect(() => {
    language === "ENG" ? setResource(EngText) : setResource(Text);
  }, [language]);

  return (
    <div className={styles.contact_container}>
      <h1 className={styles.contact_title}>{textResource.CONTACT.TITLE}</h1>
      <p className={styles.contact_paragraph}>{textResource.CONTACT.INFO}</p>
      <div className={styles.contact_person}>
        <img
          src={Kontakt01}
          alt="Janne-Elin"
          className={styles.contact_person_picture}
        />
        <p className={styles.contact_name}>
          {textResource.CONTACT.CONTACT_NAME}
        </p>
        <p>
          {textResource.CONTACT.EMAIL}
          <a
            className={styles.contact_mail_link}
            href="mailto:Janne-elin@larsgardentunhotell.no"
          >
            {textResource.CONTACT.CONTACT_EMAIL}
          </a>
        </p>
        <p>
          {textResource.CONTACT.MOBILE}
          <a className={styles.contact_mobile_number} href="tel:004795797519">
            {textResource.CONTACT.CONTACT_PHONE}
          </a>
        </p>
      </div>
      <address className={styles.contact_address}>
        {textResource.CONTACT.ORG_NAME}
        <br />
        {textResource.CONTACT.ORG_NUMBER}
        <br />
        {textResource.CONTACT.ADDRESS}
        <br />
        {textResource.CONTACT.LOCATION}
      </address>
    </div>
  );
};

export default Kontakt;
