import React from "react";
import styles from "./Layout.module.css";

const Layout = ({ header, footer, children, toTop }) => {
  return (
    <>
      <div>{header}</div>
      <div className={styles.container}>{children}</div>
      <div className={styles.to_top_container}>{toTop}</div>
      <div>{footer}</div>
    </>
  );
};

export default Layout;
