import { BrowserRouter } from "react-router-dom";
import BaseRouter from "./BaseRouter";
import React, { useState } from "react";
import "./App.css";
import Layout from "./components/layout/Layout";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import BackToTop from "./components/backToTop/BackToTop";

function App() {
  const [language, setLanguage] = useState("NOR");
  return (
    <BrowserRouter>
      <Layout
        header={<Header language={language} setLanguage={setLanguage} />}
        footer={<Footer language={language} />}
        toTop={<BackToTop language={language} />}
      >
        <BaseRouter language={language} />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
