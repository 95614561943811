import React from "react";
import styles from "./LanguageSelect.module.css";
import { Box } from "@mui/material";

const LanguageSelect = ({ lang, setLanguage }) => {
  return (
    <div className={styles.header_language_settings}>
      <Box
        component="button"
        onClick={() => {
          setLanguage("NOR");
        }}
        className={`${styles.header_language_button} ${
          lang === "NOR" && styles.activeLang
        }`}
      >
        Norsk
      </Box>
      <Box
        component="button"
        onClick={() => {
          setLanguage("ENG");
        }}
        className={`${styles.header_language_button} ${
          lang === "ENG" && styles.activeLang
        }`}
      >
        English
      </Box>
    </div>
  );
};

export default LanguageSelect;
