import React from "react";
import { Link, useLocation } from "react-router-dom";
import RoutesDefinitions from "../../RoutesDefinitions.json";
import { Stack } from "@mui/material";
import LanguageSelect from "./LanguageSelect";
import BurgerMenu from "./BurgerMenu";
import { LogoWhite } from "../../assets/img/index";
import styles from "./Header.module.css";

const Header = (props) => {
  const { language, setLanguage } = props;
  let pageSet = RoutesDefinitions.find((e) => e.language === language);
  let location = useLocation();
  let menuItems = [];

  pageSet.pages.forEach((page) => {
    if (page.isActive) {
      let isCurrentPath = location.pathname === page.path;
      menuItems.push(
        <Link key={page.name} style={{ textDecoration: "none" }} to={page.path}>
          <div className={isCurrentPath ? styles.item_selected : styles.item}>
            {page.name}
          </div>
        </Link>
      );
    }
  });

  return (
    <div className={styles.header_container}>
      <div className={styles.header_innerContainer}>
        <LanguageSelect lang={language} setLanguage={(l) => setLanguage(l)} />
        <div className={styles.header_content}>
          <Link to="/">
            <img src={LogoWhite} alt="logo" className={styles.header_logo} />
          </Link>
          <div className={styles.header_dt_navbar}>
            <Stack direction="row" alignItems="center">
              {menuItems}
            </Stack>
          </div>
          <div className={styles.header_mb_navbar}>
            <BurgerMenu language={language} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
