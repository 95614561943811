import React, { useEffect, useState } from "react";
import styles from "./Footer.module.css";
import { Text, EngText } from "../../assets/TextResources";
import {
  CallIcon,
  MailIcon,
  FacebookIcon,
  InstagramIcon,
} from "../../assets/img";

const Footer = ({ language }) => {
  const [textResource, setResource] = useState(Text);

  useEffect(() => {
    language === "ENG" ? setResource(EngText) : setResource(Text);
  }, [language]);

  return (
    <div className={styles.footer_container}>
      <h1 className={styles.footer_title}> {textResource.FOOTER.CONTACT}</h1>
      <div className={styles.footer_content}>
        <div className={styles.left_container}>
          <p>{textResource.FOOTER.INFO}</p>
          <div className={styles.contact}>
            <p>{textResource.FOOTER.PHONE}</p>
            <p>{textResource.FOOTER.MAIL}</p>
          </div>
          <address className={styles.footer_address}>
            {textResource.CONTACT.ADDRESS}
            <br />
            {textResource.CONTACT.LOCATION}
          </address>
        </div>
        <div className={styles.right_container}>
          <p className={styles.copyright}>{textResource.FOOTER.COPYRIGHT}</p>
          <div className={styles.social_medias_text}>
            <p>
              {textResource.FOOTER.FB}
              <a
                className={styles.links}
                href="https://www.facebook.com/Larsgardentunhotell"
                target="_blank"
                rel="noreferrer"
              >
                {textResource.FOOTER.FB_FARM}
              </a>
            </p>
            <p>
              {textResource.FOOTER.IG}
              <a
                className={styles.links}
                href="https://www.instagram.com/larsgardentunhotell/"
                target="_blank"
                rel="noreferrer"
              >
                {textResource.FOOTER.IG_FARM}
              </a>
            </p>
          </div>
          <div className={styles.social_medias_icons}>
            <a href="tel:95797519">
              <img
                src={CallIcon}
                alt="Call 95797519"
                className={styles.social}
              />
            </a>
            <a href="mailto:Janne-elin@larsgardentunhotell.no">
              <img
                src={MailIcon}
                alt="Send email to Janne-elin@larsgardentunhotell.no"
                className={styles.social}
              />
            </a>
            <a href="https://www.facebook.com/Larsgardentunhotell">
              <img
                src={FacebookIcon}
                alt="Facebook"
                className={styles.social}
                target="_blank"
                rel="noreferrer"
              />
            </a>
            <a href="https://www.instagram.com/larsgardentunhotell/">
              <img
                src={InstagramIcon}
                alt="Instagram"
                className={styles.social}
                target="_blank"
                rel="noreferrer"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
