import React, { useEffect, useState } from "react";
import styles from "./Vertskapet.module.css";
import { Vertskapet01, Vertskapet02 } from "../assets/img/";
import { GApageView } from "../index.js";
import { Text, EngText } from "../assets/TextResources";

const Vertskapet = ({ language }) => {
  const [textResource, setResource] = useState(Text);

  useEffect(() => {
    GApageView("/Vertskapet");
  }, []);
  useEffect(() => {
    language === "ENG" ? setResource(EngText) : setResource(Text);
  }, [language]);

  return (
    <div className={styles.host_container}>
      <img src={Vertskapet01} alt="Janne" className={styles.host_hero_image} />
      <div className={styles.host_paragraph_wrapper}>
        <div className={styles.host_left_paragraph}>
          <p>{textResource.ABOUT.FIRST_PARAGRAPH}</p>
        </div>
        <div className={styles.host_right_paragraph}>
          <p>{textResource.ABOUT.SEC_PARAGRAPH}</p>
        </div>
      </div>
      <img
        src={Vertskapet02}
        alt="Einvald og Gerd"
        className={styles.host_hero_image}
      />
    </div>
  );
};

export default Vertskapet;
