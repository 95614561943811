import React, { useEffect, useState } from "react";
import styles from "./Aktivitetar.module.css";
import { AktivitetHero, Aktivitet02 } from "../assets/img/";
import { GApageView } from "../index.js";
import { Text, EngText } from "../assets/TextResources";

const Aktivitetar = ({ language }) => {
  const [textResource, setResource] = useState(Text);

  useEffect(() => {
    GApageView("/Aktivitetar");
  }, []);
  useEffect(() => {
    language === "ENG" ? setResource(EngText) : setResource(Text);
  }, [language]);

  return (
    <div className={styles.trips_container}>
      <img
        src={AktivitetHero}
        alt="View of the fjord"
        className={styles.trips_hero_picture}
      />
      <h1 className={styles.trips_title}>{textResource.SURROUDINGS.TITLE}</h1>
      <div className={styles.trips_paragraphs_container}>
        {Object.entries(textResource.SURROUDINGS.ACTIVITIES).map(
          ([key, value]) => {
            return (
              <div key={key} className={styles.trips_paragraph_wrapper}>
                <div className={styles.trips_paragraph_title}>
                  <p className={styles.trips_paragraph_title_mobile}>
                    {value.TITLE}
                  </p>
                </div>
                <div className={styles.trips_paragraph_text}>
                  <p>{value.DESCRIPTION}</p>
                </div>
              </div>
            );
          }
        )}
      </div>
      <img
        src={Aktivitet02}
        alt="View of the fjord"
        className={styles.trips_hero_picture}
      />
    </div>
  );
};

export default Aktivitetar;
