import React, { useEffect, useState } from "react";

import Carousel, { CarouselItem } from "../components/carousel/Carousel";
import styles from "./Maten.module.css";
import { Text, EngText } from "../assets/TextResources";
import {
  Maten01,
  Maten02,
  Maten03,
  Maten04,
  Maten05,
  Maten06,
  Maten07,
  Maten08,
  Maten09,
  Maten10,
  Maten11,
  LogoGreenSimple,
} from "../assets/img";
import { GApageView } from "../index.js";

const Maten = ({ language }) => {
  const [textResource, setResource] = useState(Text);
  useEffect(() => {
    GApageView("/Maten");
  }, []);
  useEffect(() => {
    language === "ENG" ? setResource(EngText) : setResource(Text);
  }, [language]);

  return (
    <div className={styles.food_container}>
      <img src={Maten01} alt="Gamlestova" className={styles.food_image} />
      <div className={styles.food_paragraph_wrapper}>
        <h1 className={styles.food_title_paragraph}>
          {textResource.FOOD.TITLE}
        </h1>
        <p>{textResource.FOOD.FIRST_PARAGRAPH}</p>
      </div>
      <img src={Maten02} alt="Gamlestova" className={styles.food_image} />
      <div className={styles.food_paragraph_wrapper}>
        <h1 className={styles.food_title_paragraph}>
          {textResource.FOOD.SEC_TITLE}
        </h1>
      </div>
      <div className={styles.food_paragraph_wrapper}>
        {Object.entries(textResource.FOOD.MEALS).map(([key, value]) => {
          return (
            <div key={key} className={styles.food_meal_details}>
              <div className={styles.food_meal_title}>
                <p className={styles.food_meal_title_mobile}>{value.TITLE}</p>
              </div>
              <div className={styles.trips_paragraph_text}>
                <p>{value.DESCRIPTION}</p>
              </div>
            </div>
          );
        })}
      </div>
      <Carousel language={language}>
        <CarouselItem>
          <img
            src={Maten03}
            alt="the food"
            className={styles.food_image_carousel}
          />
        </CarouselItem>
        <CarouselItem>
          <img
            src={Maten04}
            alt="the food"
            className={styles.food_image_carousel}
          />
        </CarouselItem>
        <CarouselItem>
          <img
            src={Maten05}
            alt="the food"
            className={styles.food_image_carousel}
          />
        </CarouselItem>
        <CarouselItem>
          <img
            src={Maten06}
            alt="the food"
            className={styles.food_image_carousel}
          />
        </CarouselItem>
        <CarouselItem>
          <img
            src={Maten07}
            alt="the food"
            className={styles.food_image_carousel}
          />
        </CarouselItem>
        <CarouselItem>
          <img
            src={Maten08}
            alt="the food"
            className={styles.food_image_carousel}
          />
        </CarouselItem>
        <CarouselItem>
          <img
            src={Maten09}
            alt="the food"
            className={styles.food_image_carousel}
          />
        </CarouselItem>
        <CarouselItem>
          <img
            src={Maten10}
            alt="the food"
            className={styles.food_image_carousel}
          />
        </CarouselItem>
        <CarouselItem>
          <img
            src={Maten11}
            alt="the food"
            className={styles.food_image_carousel}
          />
        </CarouselItem>
      </Carousel>
      <div className={styles.food_logo_image_wrapper}>
        <img
          className={styles.food_logo_image}
          src={LogoGreenSimple}
          alt="LogoGreenLeafSimple"
        />
      </div>
    </div>
  );
};

export default Maten;

// return (
//   <div className={styles.matfat_container}>
//     <div>
//       <img
//         src={Maten01}
//         alt="Janne med lurt smil"
//         className={styles.matfat_hero_image}
//       />
//     </div>
//     <div className={styles.matfat_paragraph_container}>
//       <div className={styles.matfat_paragraph_logo}>
//         <img
//           src={LogoGreen}
//           alt="LogoGreen"
//           className={styles.matfat_logo_image}
//         />
//       </div>
//       <div className={styles.matfat_paragraph_text_container}>
//         <div className={styles.matfat_header_text}>
//           <p>{textResource.FOOD.FIRST_PARAGRAPH}</p>
//         </div>
//         <div className={styles.matfat_paragraph_text}>
//           <img
//             src={HeidiOgPernille}
//             alt="Heidi og Pernille"
//             className={styles.matfat_paragraph_image}
//           />
//         </div>
//         <div className={styles.matfat_paragraph_text}>
//           <p>{textResource.FOOD.SEC_PARAGRAPH}</p>
//         </div>
//         <div className={styles.matfat_paragraph_text}>
//           <p>{textResource.FOOD.THIRD_PARAGRAPH}</p>
//         </div>
//       </div>
//     </div>
//     <div className={styles.matbilder_container}>
//       <div className={styles.matbilde_wrapper}>
//         <img src={Maten02} alt="Bilde av urter" className={styles.matbilde} />
//       </div>
//       <div className={styles.matbilde_wrapper}>
//         <img src={Maten03} alt="Bilde av urter" className={styles.matbilde} />
//       </div>
//       <div>
//         <img src={Maten04} alt="Bilde av urter" className={styles.matbilde} />
//       </div>
//     </div>
//     <div className={styles.matbilder_container}>
//       <div className={styles.matbilde_wrapper}>
//         <img src={Maten05} alt="Bilde av urter" className={styles.matbilde} />
//       </div>
//       <div className={styles.matbilde_wrapper}>
//         <img src={Maten06} alt="Bilde av urter" className={styles.matbilde} />
//       </div>
//       <div>
//         <img src={Maten07} alt="Bilde av urter" className={styles.matbilde} />
//       </div>
//     </div>

//     <div className={styles.serveringstyper_container}>
//       <div className={styles.serveringstype_wrapper}>
//         <div className={styles.serveringstittel}>
//           <p>{textResource.FOOD.MEALS.BREAKFAST.TITLE}</p>
//         </div>
//         <div className={styles.serveringsbeskrivelse}>
//           <p>{textResource.FOOD.MEALS.BREAKFAST.DESCRIPTION}</p>
//         </div>
//       </div>
//       <div className={styles.serveringstype_wrapper}>
//         <div className={styles.serveringstittel}>
//           <p>{textResource.FOOD.MEALS.LUNCH.TITLE}</p>
//         </div>
//         <div className={styles.serveringsbeskrivelse}>
//           <p>{textResource.FOOD.MEALS.LUNCH.DESCRIPTION}</p>
//         </div>
//       </div>
//       <div className={styles.serveringstype_wrapper}>
//         <div className={styles.serveringstittel} />
//         <div className={styles.serveringsbeskrivelse}>
//           <img
//             src={Maten09}
//             alt="God vin i glasset"
//             className={styles.matbilde}
//           />
//         </div>
//       </div>
//       <div className={styles.serveringstype_wrapper}>
//         <div className={styles.serveringstittel}>
//           <p>{textResource.FOOD.MEALS.DINNER.TITLE}</p>
//         </div>
//         <div className={styles.serveringsbeskrivelse}>
//           <p>{textResource.FOOD.MEALS.DINNER.DESCRIPTION}</p>
//         </div>
//       </div>
//       <div className={styles.serveringstype_wrapper}>
//         <div className={styles.serveringstittel}>
//           <p>{textResource.FOOD.MEALS.DRINKS.TITLE}</p>
//         </div>
//         <div className={styles.serveringsbeskrivelse}>
//           <p>{textResource.FOOD.MEALS.DRINKS.DESCRIPTION}</p>
//         </div>
//       </div>
//     </div>
//     <div className={styles.matfat_image_container}>
//       <p className={styles.matfat_image_text}>
//         {textResource.FOOD.LAST_PARAGRAPH}
//       </p>
//       <img
//         src={Maten08}
//         alt="Yellow flowers"
//         className={styles.matfat_hero_image}
//       />
//     </div>
//   </div>
// );
