import React, { useEffect, useState } from "react";
import styles from "./Plassering.module.css";
import FjordView from "../assets/img/photos/BergeVedHoyangsfjorden.jpg";
import { GApageView } from "../index.js";
import { Text, EngText } from "../assets/TextResources";

const Plassering = ({ language }) => {
  const [textResource, setResource] = useState(Text);

  useEffect(() => {
    language === "ENG" ? setResource(EngText) : setResource(Text);
  }, [language]);
  useEffect(() => {
    GApageView("/Plassering");
  }, []);

  return (
    <div className={styles.berge_container}>
      <div className={styles.berge_hero_picture_wrapper}>
        <img
          src={FjordView}
          alt="View of the fjord"
          className={styles.berge_hero_picture}
        />
      </div>
      <div className={styles.berge_content_container}>
        <div className={styles.berge_information_container}>
          <div className={styles.berge_map_wrapper}>
            {/* <iframe
              title="Plasseringa til garden"
              src="https://storage.googleapis.com/maps-solutions-pe72gikwus/locator-plus/asq6/locator-plus.html"
              width="100%"
              height="100%"
              style={{ border: "0" }}
              loading="lazy"
              ></iframe> */}
            <iframe title="Plasseringa til garden"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d492485.3792194789!2d5.3951043566276695!3d61.17241563886677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x463d89b3a1d4ed9b%3A0x3a2f7b1d15782dee!2sLarsgarden%20Tunhotell!5e0!3m2!1sen!2sno!4v1687950321126!5m2!1sen!2sno"               style={{ border: "0", height: "100%", width: "100%" }}
              allowFullScreen={true} 
              loading="lazy"
              zoomLevel="1"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className={styles.berge_text_container}>
            <h3 className={styles.berge_title}>{textResource.BERGE.TITLE}</h3>
            <p>{textResource.BERGE.INGRESS}</p>
            <p>{textResource.BERGE.MAP_DESCRIPTION1}</p>
            <p>{textResource.BERGE.MAP_DESCRIPTION2}</p>
            <p>{textResource.BERGE.MAP_DESCRIPTION3}</p>
          </div>
        </div>
        <div className={styles.berge_paragraphs}>
          <p>{textResource.BERGE.PARAGRAPH1}</p>
          <p>{textResource.BERGE.PARAGRAPH2}</p>
        </div>
      </div>
    </div>
  );
};

export default Plassering;
